/* Ensure content width does not exceed page width */
#contractPDF {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  /* Add page breaks if necessary */
  .page-break {
    page-break-before: always;
  }
  
  /* Specific styles for report components */
  .Report1, .Report2, .Report3 {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  