.custom-select-wrapper {
    position: relative;
  }
  
  .custom-select-input {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .custom-select-options {
    position: absolute;
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    border-top: none;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  
  .custom-select-option {
    padding: 8px 10px;
    list-style: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .custom-select-option:hover {
    background-color: #f2f2f2;
  }
  